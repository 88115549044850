.faq {
    .popup-box {
        @include popup-with-timer-displayed;
        width: 800px;
        font-size: 1rem;
    }

    .popup-close {
        top: 10px;
        right: 10px;
        transform: translate(0, 0);
    }

    &-title {
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: 0.86rem;
        letter-spacing: 0.2em;
        color: $color-primary;
    }

    &-body {
        padding-top: 32px;
    }
}

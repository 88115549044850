.toolbox-briefing {
    .popup-box {
        width: 800px;
        font-size: 1rem;
        @include popup-with-timer-displayed;
    }

    .popup-close {
        top: 10px;
        right: 10px;
        transform: translate(0, 0);
    }

    .briefing {
        &-title {
            text-align: center;

            &::after {
                content: "";
                display: block;
                width: 8em;
                height: 2px;
                margin: 0.8em auto 1.2em auto;
                background-color: $color-primary;
            }
        }

        &-content {
            line-height: 1.4;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $color-primary;
            }
        }

        &-steps {
            margin-top: 3em;
            margin-bottom: 3em;
        }

        &-step {
            $parent: ".briefing-step";
            margin-top: 0.6em;
            display: flex;
            opacity: 0.6;
            color: $color-light;

            &:nth-child(1) {
                margin-top: 0;
            }

            &-number {
                @extend %flex-center;
                width: 3em;
                margin-right: 0.6em;
                padding: 10px 20px;
                flex-shrink: 0;
                border-radius: 10px;
                background-color: $color-button-disabled;

                & > * {
                    margin: 0;
                }
            }

            &-body {
                padding: 14px;
                flex-grow: 1;
                margin: 0;
                border-radius: 10px;
                background-color: $color-button-disabled;
            }

            &.is-active {
                opacity: 1;

                #{$parent}-number {
                    background-color: $color-primary;
                }

                #{$parent}-body {
                    background-color: $color-primary;
                }
            }
        }
    }
}

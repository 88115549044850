#subscribe-leader {
    .subscribe-steps.row {
        $local-gutter: 30px;
        $local-gutter-medium: 20px;
        $margin-top: 50px;
        margin-left: (0- $local-gutter);
        margin-right: (0- $local-gutter);
        // margin-top: ($margin-top - $local-gutter);
        margin-top: 80px;
        @media screen and (max-width: 1300px) {
            margin-left: (0 - $local-gutter-medium);
            margin-right: (0 - $local-gutter-medium);
        }

        .col {
            flex: 0 0 23%;
            max-width: 23%;
            padding: $local-gutter;
            @media screen and (max-width: 1300px) {
                padding: $local-gutter-medium;
            }

            @include media-breakpoint-down(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    label.step-item-label {
        display: block;
        margin-bottom: 0.4em;
    }

    .participant-wrapper {
        .participant-item {
            position: relative;
            margin-top: 10px;

            &:nth-child(1) {
                margin-top: 0;
            }

            @keyframes remove_appear {
                0% {
                    opacity: 0;
                    transform: translate(-50%, -50%) scale(0.6);
                }

                100% {
                    opacity: 1;
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            button.remove-participant {
                @extend %absolute-center;
                left: 100%;
                margin-top: 0;
                padding: 0;
                height: 2.2rem;
                width: 2.2rem;
                border-radius: 2.2rem;

                animation-name: remove_appear;
                animation-duration: 200ms;
                animation-timing-function: ease-out;

                &:disabled {
                    display: none;
                }
            }
        }
    }

    button.add-participant {
        position: relative;
        padding: 0;
        height: 36px;
        width: 36px;

        .far {
            @extend %absolute-center;
            margin: 0;
            font-size: 1.2rem;
        }
    }

    .item-field {
        position: relative;
        margin-top: 16px;

        .tooltip {
            position: absolute;
            left: 0;
            top: 2px;
            width: 100%;

            &-toggle {
                @extend %flex-center;
                height: 1.4rem;
                width: 1.4rem;
                padding: 0;
                margin: 0;
                margin-left: auto;
                font-size: 0.9em;
                font-weight: 700;
                border-radius: 50%;
                color: $color-dark;
                background-color: $color-light;

                &:hover,
                &:focus {
                    color: $color-light;
                    background-color: $color-primary;
                }
            }

            &-box {
                padding: 6px 10px;
                font-size: 0.86em;
                line-height: 1.3;
                border-radius: 10px;
                border: 1px solid $color-primary;
                color: $color-dark;
                background-color: $color-light;
            }
        }

        &:nth-child(1) {
            margin-top: 0;
        }

        textarea {
            height: 120px;
        }
    }

    .future {
        margin-top: 14px;

        &-setdate {
            opacity: 0;
            pointer-events: none;
            transition: $transition--fast;
            // display: none;
            margin-top: 20px;
        }

        &.is-selected .future-setdate {
            // display: block;
            opacity: 1;
            pointer-events: all;
        }
    }
}

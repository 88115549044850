.laptop-email {
    $local-dark: #2f2f2f;
    $local-pink: #ed7a71;
    $local-padding: 10px;
    @extend %playroom-app;
    height: 100%;
    width: 100%;
    text-align: left;
    text-align: start;
    font-size: 1rem;
    background-color: $local-dark;

    .email {
        height: 100%;
        display: flex;
        font-size: 0.86em;

        button {
            font-size: 1em;
        }

        &-left {
            padding: 20px 8px;
            // flex-basis: 26%;
            width: 170px;
            flex-shrink: 0;
        }

        &-center {
            overflow: auto;
            flex-basis: 30%;
            background-color: $color-light;
        }

        &-messages {
            position: relative;
            min-height: 100%;

            &-empty {
                @extend %absolute-center;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
                text-align: center;
            }
        }

        &-right {
            flex-basis: 64%;
            overflow: hidden;
        }

        &-compose {
            width: 100%;
            padding: 6px 10px;

            .far {
                margin-right: 6px;
            }
        }

        &-categories {
            margin-top: 20px;

            button {
                display: block;
                width: 100%;
                padding: 6px 10px;
                text-align: left;
                opacity: 0.6;
                background-color: transparent;

                &:hover,
                &:focus {
                    background-color: mix($color-light, transparent, 10);
                }

                &.is-active {
                    opacity: 1;
                    font-weight: 700;
                }
            }
        }

        &-mailbox {
            height: 100%;
            padding: 10px;

            display: none;
            // Animation stuff
            // transition: $transition--primary;
            // opacity: 0;
            // transform: translateX(-100px);
            // pointer-events: none;

            &.is-open {
                display: block;
                // opacity: 1;
                // transform: translateX(0);
                // pointer-events: all;
            }
        }

        form,
        fieldset {
            height: 100%;
        }
    }

    // Represent message or inbox item
    .message {
        position: relative;
        width: 100%;
        padding: $local-padding;
        line-height: 1.2;
        text-align: left;
        border-bottom: 1px solid mix($color-dark, transparent, 16);
        color: $color-dark;
        background-color: transparent;

        &::before {
            content: "";
            position: absolute;
            display: none;
            top: 30px;
            right: 10px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $color-primary;
        }

        &:hover,
        &:focus {
            background-color: mix($color-dark, transparent, 10);
        }

        &.is-active {
            color: $color-light;
            background-color: $local-pink;
        }

        &.is-hidden {
            display: none;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            font-weight: 700;
        }

        &-title {
            font-size: 1.1em;
        }

        &-timestamp {
            padding-left: 1em;
            flex-shrink: 0;
            font-size: 0.86em;
        }

        &-content {
            margin-top: 0.4em;
        }

        &.unread {
            &::before {
                display: block;
            }

            .message-timestamp {
                color: $color-primary;
            }
        }
    }

    // Represnt email mailbox, for user to answer the question
    .mailbox {
        $maibox-header-height: 104px;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background-color: $color-light;

        select {
            padding: 0.2em 0.6em;
            border-radius: 2px;
        }

        &-header {
            height: $maibox-header-height;
            flex-shrink: 0;
            font-size: 1.1em;
            padding: $local-padding;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid mix($local-dark, transparent, 10);

            &-block {
                display: flex;
                margin-top: 0.2em;

                &:nth-child(1) {
                    margin-top: 0;
                }

                &-title {
                    width: 70px;
                    flex-shrink: 0;
                    font-weight: 700;

                    &.w-100 {
                        width: 100%;
                    }
                }

                &-content {
                    width: 100%;
                    flex-grow: 1;
                }

                select {
                    width: 200px;
                    max-width: 100%;
                }

                input {
                    width: 100%;
                    font-weight: normal;
                    padding: 0.2em 0.6em;
                }
            }

            &-title,
            &-subject {
                font-weight: 700;
            }

            &-title {
                font-size: 1.1em;
            }

            &-to {
                margin-top: 10px;
                font-size: 0.875em;
            }
        }

        &-box {
            max-height: calc(100% - #{$maibox-header-height});
            flex: 1;
            // overflow: auto;
            display: flex;
            flex-direction: column;
            line-height: 1.2;
        }

        &-content {
            flex: 1;
            overflow: auto;
            padding: $local-padding;
            line-height: 1.5;

            input,
            .select-wrapper select {
                padding: 0.1em 0.4em;
                margin: 2px;
            }

            input {
                width: 80px;
                display: inline-block;
                text-align: center;
            }

            .select-wrapper {
                display: inline;

                select {
                    width: 140px;
                }
            }
        }

        &-statement {
            &-counter {
                margin-top: 10px;
                margin-bottom: 4px;
                text-align: right;
                text-align: end;
                font-weight: bold;
            }

            &-input {
                // height: 200px;
            }

            &.is-overload {
                color: $color-primary;
            }
        }

        textarea {
            height: 14em;
            box-shadow: none;
            border-width: 1px;
            border-style: solid;
            border-color: mix($color-dark, transparent, 20);

            &:hover,
            &:focus {
                border-color: $color-primary;
            }
        }

        &-actions {
            flex-shrink: 0;
            padding: $local-padding;
            text-align: right;
            text-align: end;
            font-size: 1.1em;
            border-top: 1px solid mix($color-dark, transparent, 10);

            button {
                min-width: 30%;

                .far {
                    margin-right: 4px;
                }
            }
        }

        &-phase3-item {
            margin-top: 2em;

            &:nth-child(1) {
                margin-top: 0;
            }

            label {
                display: block;
                margin-bottom: 0.6em;
                font-weight: 700;
            }

            input,
            textarea {
                display: block;
                text-align: left;
            }

            input {
                width: 200px;
                max-width: 100%;
                padding: 6px 10px;
            }
        }
    }

    // ------------------------------------------------------------------------
    // Template
    // ------------------------------------------------------------------------
    .email-mailbox.template-view .mailbox {
        // &-header {
        //     &-title {
        //         width: 100%;
        //     }
        // }
    }

    .back-button-wrapper {
        margin-bottom: 2em;
    }

    .back-button {
        padding: 0.2em 0.4em;
        font-size: 0.875em;
        border-color: $color-light;
        color: $color-light;

        .far {
            margin-right: 0.2em;
        }

        &:hover,
        &:focus {
            background-color: mix($color-light, transparent, 20);
        }
    }

    .evidence {
        $evidence-photo-size: 60px;
        position: relative;
        padding: 20px 0;

        &-item {
            position: relative;
            margin-top: 10px;

            &.is-selected {
                .evidence-select-image,
                .evidence-select-title.selected {
                    display: block;
                }

                .evidence-select-title.not-selected {
                    display: none;
                }
            }

            &.is-open {
                .evidence-option-wrapper {
                    pointer-events: inherit;
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &-select {
            // width: 300px;
            // height: $evidence-photo-size;
            width: 75%;
            padding: 0;
            display: flex;
            align-items: center;
            // color: $color-primary;
            // border: 1px solid $color-primary;
            // background-color: transparent;
            // justify-content: space-between;
            @media screen and (max-width: 1400px) {
                width: 85%;
            }
            @media screen and (max-width: 1300px) {
                width: 100%;
            }

            &-image {
                display: none;
                height: $evidence-photo-size;
                width: $evidence-photo-size;
                flex-shrink: 0;
                flex-grow: 0;
                background-size: cover;
                background-position: center;
            }

            &-inner {
                width: 100%;
                padding: 10px 14px;
                flex-grow: 1;
                flex-shrink: 1;
                display: flex;
                justify-content: space-between;
            }

            &-title {
                text-align: left;
                text-align: start;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &.selected {
                    display: none;
                }
            }
        }

        &-option-wrapper {
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 300px;
            max-height: 250px;
            overflow: auto;
            transition: $transition--primary;
            opacity: 0;
            transform: translateY(18px);
            pointer-events: none;
            z-index: 9;
            border: 1px solid mix($color-primary, transparent, 60);
            border-radius: 2px;
            box-shadow: 0 4px 8px 4px mix($color-dark, transparent, 10);
            background-color: $color-light;
        }

        &-option {
            width: 100%;
            display: flex;
            align-items: center;

            // reset button style
            padding: 0;
            margin: 0;
            color: inherit;
            border-radius: 0;
            background-color: transparent;

            &:nth-child(even) {
                background-color: mix($color-dark, transparent, 4);
            }

            &:hover,
            &:focus {
                background-color: mix($color-dark, transparent, 10);
            }

            &-image {
                position: relative;
                height: $evidence-photo-size;
                width: $evidence-photo-size;
                background-size: cover;
                background-position: center;
            }

            &-title {
                margin-left: 1em;
            }
        }
    }

    .statement-attachment-wrapper {
        $button-gutter: 6px;
        display: flex;
        flex-wrap: wrap;
        margin-left: (0 - $button-gutter);
        margin-right: (0 - $button-gutter);

        .statement-attachment {
            margin: ($button-gutter / 2);
            display: block;
        }
    }

    &-restricted-popup {
        text-align: center;

        &-title {
            &::after {
                content: "";
                display: block;
                width: 8em;
                height: 2px;
                margin: 0.8em auto 1.2em auto;
                background-color: $color-primary;
            }
        }

        &-proceed {
            width: 9em;
            font-size: 1.1em;
        }
    }
}

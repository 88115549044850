#page-404 {
    position: relative;

    &::after {
        @extend %pseudo-overlay;
        z-index: 0;
        background-color: mix($color-dark, transparent, 60);
    }

    .error404 {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: table;
        font-size: 1.4em;
        table-layout: fixed;
        text-align: center;
        color: $color-light;

        &-tablecell {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        &-inner {
            display: inline-block;
        }

        &-code {
            font-size: 7em;
            line-height: 1;
            margin-bottom: 0;
            margin-top: 0;
        }

        &-message .btn {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.is-internet-explorer {
        &::after {
            $color: mix($color-primary, $color-dark, 15);
            background-color: mix($color, transparent, 70);
        }

        .error404-inner {
            width: 600px;
            max-width: 100%;
        }

        .error404-code {
            font-size: 2em;
        }
    }
}

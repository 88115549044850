.aspiration {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    // Some space for overloaded avatar, in case it's overload
    padding-bottom: 40px;

    &::after {
        @extend %pseudo-loading;
    }

    &.is-loading {
        .aspiration-item {
            opacity: 0;
        }

        &::after {
            display: block;
        }
    }

    &-inner {
        position: relative;
        // height: 100%;
        width: 100%;

        // This is to fix safari, it won't work with height: 100%;
        // https://stackoverflow.com/a/33644245/6049731
        height: auto;
        display: flex;
        align-self: stretch;
        // ------------------------------------------------------
    }

    @keyframes aspiration_fadein {
        0% {
            opacity: 0;
            background-color: transparent;
        }

        25% {
            background-color: mix($color-primary, transparent, 40);
        }

        50% {
            opacity: 1;
        }

        100% {
            background-color: transparent;
        }
    }

    &-item {
        position: absolute;
        // padding: 8px;
        transition: $transition--fast;

        &.is-appended {
            animation-name: aspiration_fadein;
            animation-timing-function: ease-out;
            animation-duration: 775ms;
        }

        &.is-overload {
            top: 100%;

            .aspiration-person {
                width: auto;
                padding: 0;

                &:hover {
                    background-color: transparent;
                }
            }

            .aspiration-person-avatar {
                border: 3px solid $color-dark;
            }

            .aspiration-person-name,
            .aspiration-person-aspiration {
                display: none;
            }
        }
    }

    &-person {
        cursor: pointer;
        user-select: none;
        display: flex;
        width: 340px;
        max-width: 100%;

        // NOTE: We should make every aspiration have the same height
        height: 120px;

        padding: 14px 8px;
        transition: $transition--fast;
        border-radius: 2px;
        @include media-breakpoint-down(lg) {
            font-size: 0.76rem;
        }
        @include media-breakpoint-down(hd-small) {
            // NOTE: We should make every aspiration have the same height
            height: 91px;

            width: 260px;
            font-size: 0.86rem;
        }

        & > div:nth-child(2) {
            min-width: 0;
            flex-shrink: 1;
        }

        &:hover {
            background-color: mix($color-light, transparent, 10);
        }

        &-avatar {
            height: 40px;
            width: 40px;
            margin-right: 14px;
            object-fit: cover;
            border-radius: 50%;
        }

        &-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0;
            margin-bottom: 0.2em;
        }

        &-aspiration {
            font-size: 1em;
            line-height: 1.2;
            opacity: 0.8;
        }
    }
}

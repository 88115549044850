.game-start {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    $local-color: mix($color-primary, $color-dark, 60);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zindex-navbar + 1;
    pointer-events: none;
    opacity: 0;
    transition: $transition--fast;
    background-color: mix($local-color, transparent, 90);

    &.is-active {
        pointer-events: all;
        opacity: 1;
    }

    &__counter {
        position: relative;
        height: 160px;
        width: 160px;
        margin: auto;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4.8em;
        font-weight: 700;
        border: 6px solid $color-light;
        border-radius: 50%;

        &::after {
            @extend %pseudo-loading;
            font-size: 0.8em;
        }

        &.is-loading span {
            display: none;
        }

        &.is-loading::after {
            display: block;
        }
    }
}

%playroom-before-calculated {
    .playroom-wrapper {
        opacity: 0;
        pointer-events: none;
    }

    &::after {
        display: block;
    }
}

.playroom {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: $color-dark;

    // For testing Room coordinate (visual-only)
    // background-size: cover;
    // background-position: center;
    // background-image: url("https://images.unsplash.com/photo-1587613757703-eea60bd69e66?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2775&q=80");
    // &-image {
    //     display: none;
    // }

    &::after {
        @extend %pseudo-loading;
        font-size: 2.6rem;
        color: $color-light;
    }

    &-wrapper {
        @extend %absolute-center;
        z-index: 1;
        transition: opacity $transition--fast;
    }

    &-block {
        @extend %pseudo-overlay;
        // display: none;
        transition: $transition--fast;
        background-color: mix($color-dark, transparent, 36);

        // New Style
        background-color: transparent;
    }

    &-coordinate {
        @extend %absolute-center;
        z-index: 2;
    }

    &-pointer {
        $pointer-size: 20px;
        $line-height: 60px;

        // Space between "line" and "name"
        $line-space: 10px;

        // New Style
        $pointer-size: 16px;
        $line-height: 26px;
        $line-space: 6px;

        // Position X for "name"
        $name-pos-x: 0 - ($line-height * 30 / 100);

        // Position Y for "name", 80% of $line-height
        $name-pos-y: (0 - ($line-height * 80 / 100));

        position: relative;
        height: ($pointer-size * 1.8);
        width: ($pointer-size * 1.8);
        transition: $transition--fast;
        display: block;
        border-radius: 50%;
        border: 3px solid $color-primary;

        // height: ($pointer-size * 2.4);
        // width: ($pointer-size * 2.4);
        // opacity: 0;

        // New Style
        @include media-breakpoint-down(hd) {
            font-size: 0.86em;
            // background-color: blue;
        }

        &::after {
            @extend %absolute-center;
            content: "";
            height: $pointer-size;
            width: $pointer-size;
            transition: $transition--fast;
            border-radius: 50%;
            background-color: $color-primary;
        }

        &-name {
            position: absolute;
            cursor: pointer;
            white-space: nowrap;
            font-size: 1.4em;
            font-weight: 700;
            border-radius: 100px;
            color: $color-primary;
            background-color: transparent;

            // New Style
            padding: 0.1em 0.4em;
            font-size: 1.1em;
            font-weight: 700;
            // pointer-events: none;
            // opacity: 0;
            border-radius: 0;
            color: $color-light;
            background-color: $color-primary;

            // opacity: 0;
        }

        &-line {
            position: absolute;
            top: 50%;
            left: 50%;
            height: $line-height;
            width: 3px;
            transform: translateX(-50%);
            background-color: $color-primary;

            // New Style
            transition: $transition--fast;
            // opacity: 0;
            // pointer-events: none;
        }

        &.top {
            .playroom-pointer-line {
                top: $name-pos-y;
            }

            .playroom-pointer-name {
                top: (0 - ($line-height + $line-space));
            }
        }

        &.bottom {
            .playroom-pointer-line {
                top: auto;
                bottom: $name-pos-y;
            }

            .playroom-pointer-name {
                top: auto;
                bottom: (0 - ($line-height + $line-space));
            }
        }

        &.right {
            .playroom-pointer-line {
                height: $line-height;
                left: auto;
                right: $name-pos-x;
                transform: translateX(50%) rotate(45deg);
            }

            .playroom-pointer-name {
                left: ($line-height + $line-space);
                transform-origin: 0% 50%;
            }
        }

        &.left {
            .playroom-pointer-line {
                height: $line-height;
                left: $name-pos-x;
                transform: translateX(-50%) rotate(-45deg);
            }

            .playroom-pointer-name {
                left: auto;
                right: ($line-height + $line-space);
                transform-origin: 100% 50%;
            }
        }

        &.bottom.right {
            .playroom-pointer-line {
                transform: translateX(50%) rotate(-45deg);
            }
        }

        &.bottom.left {
            .playroom-pointer-line {
                transform: translateX(-50%) rotate(45deg);
            }
        }

        &:hover,
        &:focus,
        &:focus-within {
            outline: none;
            // opacity: 1;

            &::after {
                opacity: 0.7;
                transform: translate(-50%, -50%) scale(0.6);
            }

            .playroom-pointer-name {
                transform: scale(1.1);
                color: $color-primary;
                background-color: transparent;
                // background-color: mix($color-light, transparent, 10);

                // New Style
                opacity: 1;
                color: $color-light;
                background-color: $color-primary;
            }

            // New Style
            .playroom-pointer-line {
                opacity: 1;
            }
        }
    }

    &-room {
        $avatar-size: 70px;
        cursor: pointer;
        transition: $transition--fast;

        &-name {
            // 80% of $avatar-size
            $padding-x: $avatar-size * 80 / 100;

            min-width: 200px;
            display: block;
            padding-right: $padding-x;
            padding-left: $padding-x;
            font-size: 1.2em;
            font-weight: 700;
            text-align: center;
            box-shadow: $boxshadow-2;
            @include media-breakpoint-down(hd-small) {
                font-size: 1em;
            }
        }

        &-members {
            $translate: 0 - ($avatar-size / 2);
            position: absolute;
            width: $avatar-size;
            transform: translate(#{$translate}, #{$translate});
            pointer-events: none;

            img {
                display: block;
                height: $avatar-size;
                width: $avatar-size;
                object-fit: cover;
                margin-top: -8px;
                border-radius: 50%;
                box-shadow: 0 -3px 6px 0 mix($color-dark, transparent, 25);

                &:nth-child(1) {
                    margin-top: 0;
                }
            }
        }
    }

    &-app {
        $app-size: 68px;
        $app-size-small: 50px;
        display: block;
        width: $app-size;
        text-align: center;
        text-decoration: none;
        transition: $transition--fast;
        color: $color-light;
        @include media-breakpoint-down(hd) {
            width: $app-size-small;
        }

        img {
            height: $app-size;
            width: $app-size;
            display: block;
            object-fit: contain;
            @include media-breakpoint-down(hd) {
                height: $app-size-small;
                width: $app-size-small;
            }
        }

        &-name {
            width: $app-size;
            margin-top: 8px;
            line-height: 1.2;
            font-size: 0.86em;
            @include media-breakpoint-down(hd) {
                width: $app-size-small;
            }
            @include media-breakpoint-down(hd-small) {
                font-size: 0.7em;
            }
        }

        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }

    &-login {
        width: 20em;
        padding: 2em 1em;
        transition: $transition--fast;
        font-size: 0.86em;
        background-color: mix($color-dark, transparent, 60);

        &-inner {
            transition: $transition--fast;
        }

        &-logo {
            height: 50px;
            width: 50px;
            object-fit: contain;
            display: block;
            margin: auto;
        }

        input {
            padding: 0.8em 1em;
            margin-top: 1em;
        }

        button {
            display: block;
            padding: 0.4em 1em;
            margin: auto;
            margin-top: 0.8em;
            font-size: 1em;
        }

        &-error {
            @extend %absolute-center;
            @extend %flex-center;
            width: 95%;
            padding: 0.6em 1em;
            justify-content: space-between;
            opacity: 0;
            transition: $transition--fast;
            pointer-events: none;
            background-color: $color-light;

            &-message {
                flex: 1;
            }

            button {
                display: block;
                padding: 0;
                margin: 0;
                height: 2em;
                width: 2em;
                flex-shrink: 0;
                border-radius: 50%;

                .far {
                    margin: 0;
                }
            }
        }

        &.is-hidden {
            transform: scale(0.8);
            opacity: 0;
            pointer-events: none;
        }

        &.is-error {
            .playroom-login-inner {
                opacity: 0;
                pointer-events: none;
            }

            .playroom-login-error {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    & {
        @extend %playroom-before-calculated;
    }

    &.is-calculated {
        .playroom-wrapper {
            opacity: 1;
            pointer-events: inherit;
        }

        &::after {
            display: none;
        }
    }

    &.is-loading {
        &::after {
            display: block;
        }

        // New Style
        .playroom-block {
            background-color: mix($color-dark, transparent, 40);
        }

        .playroom-pointer,
        .playroom-room {
            opacity: 0;
            pointer-events: none;
        }
    }
}

@include easyspa-loading {
    .playroom {
        @extend %playroom-before-calculated;
    }
}

#end-page {
    $swiper-bullet-gutter: 4px;
    $icon-size: 6rem;
    $box-gutter: 20px;
    color: $color-light;
    background-size: cover;
    background-position: center;

    &::before {
        @extend %pseudo-overlay;
        background-color: mix($color-dark, transparent, 60);
    }

    // Base Layout
    // ------------------------------------------------------------------------
    .end-page {
        position: relative;
        height: 100vh;
        overflow: auto;
        z-index: 2;
    }

    .end-page-wrapper {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 80px;
        padding-left: 80px;

        .container {
            height: 100%;
            width: unset;
        }
    }

    .end-page-table {
        position: relative;
        height: 100%;
        width: 100%;
        display: table;
        table-layout: fixed;
        z-index: 2;
    }

    .end-page-table-cell {
        display: table-cell;
        vertical-align: middle;
        margin: auto;
    }

    .end-page-table-inner {
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    // Main
    // ------------------------------------------------------------------------
    .end-page-main-icon svg {
        width: $icon-size;
        height: $icon-size;

        path {
            fill: $color-light;
        }
    }

    .end-page-main {
        width: 750px;
        max-width: 100%;
        margin: auto;
        padding-top: $navbar-height;
        padding-bottom: $navbar-height;
        text-align: center;
    }

    .end-page-main-row {
        justify-content: center;
        margin-top: 40px;
        margin-left: (0 - $box-gutter);
        margin-right: (0 - $box-gutter);
    }

    .end-page-main-box {
        height: auto;
        padding: $box-gutter;
        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &-inner {
            height: 100%;
            width: 240px;
            max-width: 100%;
            text-align: center;
            font-weight: 700;
            background-color: $color-primary;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &-title {
            padding: 8px 10px;
            background-color: mix($color-primary, $color-dark, 80);
        }

        &-content {
            padding: 10px;
            font-size: 2.4em;
        }
    }

    // Debriefing
    // ------------------------------------------------------------------------
    .end-page-debriefing {
        overflow: auto;
        max-height: calc(100vh - #{$navbar-height * 2});
    }

    .end-page-debriefing-inner {
        position: relative;
        padding: 40px 60px;
        padding-top: 60px;
        margin: auto;
        font-size: 18px;
        @include make-col(10);
        @include media-breakpoint-down(lg) {
            @include make-col(12);
            padding: 40px;
            padding-top: 60px;
        }

        // &::before {
        //     content: attr(data-title);
        //     position: absolute;
        //     top: 30px;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        //     display: block;
        //     text-transform: uppercase;
        //     font-weight: bold;
        //     font-size: 0.86rem;
        //     letter-spacing: 0.2em;
        //     color: $color-primary;
        // }
    }

    // Slider
    // ------------------------------------------------------------------------
    .swiper-container {
        margin-top: $navbar-height;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        width: 50px;
        font-size: 1.2rem;
        cursor: pointer;
        box-shadow: 0 2px 40px 10px rgba($color-dark, 0.4);

        &[disabled] {
            cursor: not-allowed;
        }

        // Invert the color
        // ----------------------------
        border: 2px solid $color-light;
        color: $color-primary;
        background-color: $color-light;

        &:hover,
        &:focus {
            border-color: $color-primary;
        }

        &[disabled] {
            color: $color-dark;
            border-color: $color-button-disabled;
            background-color: $color-button-disabled;
        }
        // ----------------------------
    }

    .swiper-slide {
        height: auto;
    }

    .swiper-button-prev {
        left: 20px;
    }

    .swiper-button-next {
        right: 20px;
    }

    .swiper-pagination-bullets {
        position: absolute;
        z-index: 2;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        margin: (0 - $swiper-bullet-gutter);
    }

    .swiper-pagination-bullet {
        display: block;
        height: 12px;
        width: 12px;
        margin: $swiper-bullet-gutter;
        opacity: 0.2;
        transition: opacity $transition--primary;
        border-radius: 50%;
        background-color: $color-primary;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

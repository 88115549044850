#main-office {
    .main-office-popup {
        &-bottom {
            margin-top: 5.4em;
            text-align: center;
        }

        &-button {
            margin-top: 0.9em;
            padding: 0.3em 1.8em;
            font-size: 1.1em;
            border: 1px solid transparent;

            span.text-active {
                display: none;
            }

            &.is-active {
                color: $color-primary;
                border-color: $color-primary;
                background-color: transparent;

                span.text-default {
                    display: none;
                }

                span.text-active {
                    display: inline;
                }
            }
        }

        &-wait {
            display: flex;
            justify-content: center;
            margin-top: 1.4em;
            opacity: 0;
            transition: $transition--primary;
            font-size: 0.74em;

            &.is-active {
                opacity: 1;
            }
        }

        &-members {
            margin-left: 0.4em;

            span::after {
                content: "|";
                display: inline-block;
            }

            span:nth-last-child(1)::after {
                content: none;
            }
        }
    }

    .popup.main-office-narrative-popup {
        background-color: mix($color-dark, transparent, 70);

        .popup-box {
            width: 72vw;
            max-width: 100%;
            height: 80vh;
            padding: 0;
            border-radius: 0;
        }
    }

    .main-office-dataroom {
        .popup-wrapper {
            background-size: cover;
            background-position: center;
        }

        .popup-box {
            @extend %width-fit-content;
            padding: 0;
            background-color: transparent;
        }

        .popup-close {
            z-index: 3;
            top: 14px;
            right: 10px;
            transform: translate(0, 0);
            box-shadow: 1px 2px 6px 0 mix($color-dark, transparent, 50);
        }

        &-error {
            display: none;
            margin-top: 0.2em;
            color: $color-danger;
        }

        &-submit {
            width: 100%;
            margin-top: 0.8em;
        }

        &.is-error .main-office-dataroom-error {
            display: block;
        }
    }

    // For testing Room coordinate (visual-only)
    // TODO: delete this
    .zoom {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 3;
    }
}

.game-info {
    $self: #{&};
    border-radius: 0 10px 0 0;
    padding-bottom: 24px;
    background-color: $color-light;
    display: flex;
    flex-direction: column;

    // Animation
    transition: $transition--primary;
    opacity: 0;
    transform: translateX(-100px);

    &__block {
        margin-top: 24px;
        padding: 0 24px;
        flex-shrink: 0;
        color: $color-dark;

        // Buttons wrapper
        &.for-buttons {
            margin-top: 16px;
            display: flex;
        }
    }

    &__content {
        position: relative;
        flex-shrink: 1;
        flex-grow: 0;
        overflow: hidden;
        font-size: 14px;

        #{$self}__block {
            margin-top: 0;
        }
    }

    &__content-scroll {
        max-height: 100%;
        overflow-y: auto;
    }

    &__content-inner {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &__divider {
        height: 1px;
        width: 100%;
        flex-shrink: 0;
        background-color: rgba($color-dark, 0.2);
    }

    &__label {
        display: block;
        margin-top: 32px;
        margin-bottom: 8px;
        font-size: 0.875rem;
        font-weight: 700;
        color: $color-primary;

        &:nth-child(1) {
            margin-top: 0;
        }
    }

    input#{$self}__input {
        box-shadow: 0 0 0 1px rgba($color-dark, 0.2);

        &:hover,
        &:focus {
            box-shadow: 0 0 0 1px $color-primary;
        }
    }

    &__group {
        position: relative;
        display: flex;

        .btn {
            width: auto;
            min-width: 1%;
            flex: 0 0 auto;
        }
    }

    &__description {
        margin-top: 8px;
        font-size: 14px;
        line-height: 1.2;
        opacity: 0.6;
    }

    &__message {
        @extend %absolute-overlay;
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-radius: 2px;
        color: $color-light;
        background-color: $color-primary;

        // Animation
        transition: $transition--primary;
        opacity: 0;
        pointer-events: none;

        &.is-open {
            opacity: 1;
            pointer-events: inherit;
        }
    }

    &__btn {
        width: 50%;
        flex-grow: 1;
        margin-left: 8px;
        border: 1px solid $color-primary;
        @include media-breakpoint-down(hd-small) {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 0.875rem;
        }

        &:nth-child(1) {
            margin-left: 0;
        }

        &.is-ready {
            color: $color-primary;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: mix($color-primary, transparent, 7);
            }
        }
    }

    &.is-open {
        transform: translateX(0);
        opacity: 1;
        pointer-events: inherit;
    }

    &.is-success-copy {
        .for-copy #{$self}__message {
            @extend .is-open;
        }
    }
}

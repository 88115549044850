// ----------------------------------------------------------------------------
// Override default style from flatpickr library
// ----------------------------------------------------------------------------

.flatpickr-calendar {
    user-select: none;
    border-radius: 2px;
    border: 1px solid $color-dark;
    box-shadow: none;
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: $color-primary;
}

// Override default style from flatpickr/confirmDate plugin
// ----------------------------------------------------------------------------
.flatpickr-confirm {
    cursor: pointer;
    transition: $transition--fast;
    font-weight: 700;
    color: $color-light;
    background-color: $color-primary;

    &:hover,
    &:focus {
        outline: none;
    }

    &:hover {
        background-color: mix($color-primary, $color-light, 90);
    }
}

.join-game {
    $self: #{&};

    .popup-box {
        width: 400px;
        padding: 24px 32px;
    }

    &__title {
        margin-bottom: 1em;
    }

    input#{&}__input {
        box-shadow: 0px 0px 0px 1px rgba($color-dark, 0.2);

        &:hover,
        &:focus {
            box-shadow: 0px 0px 0px 1px $color-primary;
        }
    }

    &__error {
        display: none;
        margin-top: 8px;
        font-size: 0.875rem;
        color: $color-primary;
    }

    &__submit {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding-left: 24px;
        padding-right: 24px;
    }

    &.is-error {
        #{$self}__error {
            display: block;
        }

        input#{$self}__input {
            color: $color-primary;
        }
    }
}

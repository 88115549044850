#waiting-room {
    min-height: 100vh;
    padding-top: $navbar-height;
    color: $color-light;
    background-position: center;
    background-size: cover;

    &::before {
        @extend %pseudo-overlay;
        opacity: 0.8;
        background-color: $color-dark;
    }
}

.waiting-room {
    $self: #{&};
    $left-width: 400px;
    position: relative;
    height: calc(100vh - #{$navbar-height});
    display: flex;
    z-index: 1;

    &__left {
        position: relative;
        width: $left-width;
        flex-shrink: 0;

        .game-info {
            height: 100%;
            width: 100%;
        }
    }

    &__right {
        width: calc(100% - #{$left-width});
        display: flex;
        flex-direction: column;

        .game-player {
            padding-bottom: 20px;
        }

        .messagebox {
            position: relative;
            bottom: unset;
            right: unset;
            margin-right: 0;
            margin-left: auto;
            flex-grow: 0;
            flex-shrink: 0;
            width: 450px;
            max-width: 100%;

            &-message {
                height: 80px;
            }

            &-inner {
                padding-top: 0;
            }
        }
    }

    &__aspiration-wrapper {
        flex-grow: 1;
        padding: 20px;

        .aspiration {
            height: 100%;
            padding-bottom: 0;

            &-item.is-overload {
                display: none;
            }
        }
    }
}

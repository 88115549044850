.checkbox {
    user-select: none;
    position: relative;
    display: flex;
    cursor: pointer;

    input[type="checkbox"] {
        display: block;
        appearance: none;
        outline: none;
        margin-right: 8px;
        padding-top: 3px;

        &:before {
            content: "";
            position: relative;
            display: block;
            height: 16px;
            width: 16px;
            // transition: $transition--fast;
            border: 2px solid $color-light;
            border-radius: 50%;
            background-color: $color-light;
        }

        &:hover:before,
        &:focus:before {
            box-shadow: 0 0 0 2px $color-primary;
            background-color: mix($color-primary, $color-light, 30);
        }

        &:checked:before {
            background-color: $color-primary;
        }
    }
}

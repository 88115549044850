// ----------------------------------------------------------------------------
// This file contains all the variable accross styles
// ----------------------------------------------------------------------------

// Colors
// ----------------------------------------------------------------------------
$color-primary: #e54134;
$color-dark: #000000;
$color-light: #ffffff;
$color-button-disabled: #707070;
$color-placeholder: #bababa;
$color-success: #68e668;
$color-ios-call: #34c759;
$color-danger: #e54134;

// Font Family
// ----------------------------------------------------------------------------
$font-family-title: "Quicksand", sans-serif;
$font-family-body: "Quicksand", sans-serif;

// Transition
// ----------------------------------------------------------------------------
$transition--slow: 425ms cubic-bezier(0.4, 0, 0.2, 1);
$transition--primary: 275ms cubic-bezier(0.4, 0, 0.2, 1);
$transition--fast: 180ms cubic-bezier(0.4, 0, 0.2, 1);

// Global Z-Index management
// ----------------------------------------------------------------------------
$zindex-navbar: 4;
$zindex-escape: 2;
$zindex-toolbox: 2;
$zindex-playroom-header: 3;
$zindex-default-popup: 9999;
$zindex-document-success-popup: 10000;
$zindex-snackbar: 10000;
$zindex-timer: 10001;
$zindex-popup-fullscreen: 10002;
$zindex-tutorial: 10003;

// Other important variable
// ----------------------------------------------------------------------------
$navbar-height: 111px;
$navbar-timer-width: 140px;
$navbar-timer-popup-space: 20px;
$boxshadow-1: 0 3px 8px 0 mix($color-dark, transparent, 16);
$boxshadow-2: 0 3px 15px 0 mix($color-dark, transparent, 30);

// NOTE: This pretty much looks like _laptop.scss just with different structure
// because this one is part of _toolbox.scss and minor differences

.toolbox-phone {
    $button-space: 90px;
    $space-top: 20px;
    $phone-width: 400px;

    // The aspect ratio of the phone image ( n% ) or you can put the actual
    // size of the image ( height / width )
    $phone-aspect-ratio: 613 / 309;

    // We need this because overflow: hidden; in the &-wrapper, this is extra
    // space to prevent close button get cut by &-wrapper
    $close-button-bump: 10px;

    background-color: mix($color-dark, transparent, 80);

    // display: none;

    .popup-box {
        position: relative;
        width: 90vw;
        margin-top: 10px;
        margin-bottom: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        background-color: transparent;
        @include popup-with-timer-displayed;
    }

    .popup-close {
        left: 50%;
        top: 0;
        transform: translate(#{$phone-width / 2}, 0%);
        z-index: 2;
    }

    .phone-wrapper {
        position: relative;
        width: $phone-width;
        max-width: 100%;
        margin: auto;
        transition: $transition--primary;
        transform: rotateX(60deg) scale(0.6);

        // Enable this if IE won't work (alternative of right .objectruler)
        // To prevent unnecessary scrolling X in IE because of .objectruler
        // left 50%, watch out popup-close above the laptop
        overflow: hidden;
        padding-top: $close-button-bump;
    }

    &.is-open .phone-wrapper {
        transform: rotateX(0deg) scale(1);
    }

    .phone-image {
        width: $phone-width;
        max-width: 100%;
    }

    // ELement that mimic the whole laptop-image size, so we could have the
    // base size of the screen
    .phone-objectruler {
        position: absolute;
        // height: 0px;
        width: $phone-width;
        max-width: 100%;
        left: 50%;
        top: 0%;
        margin-top: $close-button-bump;
        transform: translateX(-50%);
        padding-bottom: $phone-aspect-ratio * 100%;

        // To check the sizing, use this when you confuse :)
        // background-color: mix(yellow, transparent, 70);
    }

    .phone-screen {
        // display: none;
        position: absolute;
        width: 84%;
        padding-bottom: 151%;
        top: 12%;
        left: 50%;
        transform: translateX(-50%);

        // To check the sizing, use this when you confuse :)
        // background-color: mix(red, transparent, 40);
    }

    .phone-statusbar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        transform: translateY(-100%);
    }

    .phone-inner {
        @extend %pseudo-overlay;
        overflow: hidden;
        border-radius: 10px;
        color: $color-light;
        background-color: $color-dark;

        // Normalize alignment
        text-align: center;

        // To check the sizing, use this when you confuse :)
        // background-color: mix(blue, transparent, 50);
    }

    .phone-call {
        @extend %absolute-center;
        width: 100%;
        transition: $transition--fast;

        &-field {
            display: flex;
            align-items: center;

            input {
                flex-shrink: 1;
                flex-grow: 1;
                // margin-top: 20px;
                font-size: 1.6em;
                font-weight: normal;
                text-align: center;
                // border-bottom: 1px solid mix($color-light, transparent, 50);
                color: $color-light;
                background-color: transparent;

                &::placeholder {
                    opacity: 0.6;
                    font-style: italic;
                    font-size: 0.86em;
                }

                &:hover,
                &:focus {
                    box-shadow: none;
                    border-bottom-color: $color-light;
                    background-color: transparent;
                }
            }

            &-delete {
                flex-grow: 0;
                flex-shrink: 0;
                padding: 0;
                line-height: 0;
                background-color: transparent;

                &.is-hidden {
                    display: none;
                }

                svg path {
                    fill: $color-light;
                }
            }
        }

        &-name,
        &-status {
            display: none;
        }

        &-wrongnumber {
            display: none;
            padding-left: 0.5em;
            padding-right: 0.5em;
            margin-top: 0.4em;
            line-height: 1.2;
            color: mix($color-primary, $color-light, 70);

            &.is-active {
                display: block;
            }
        }

        &-body {
            position: relative;
            margin-top: 1.4em;
            @include media-breakpoint-down(hd) {
                font-size: 1.2em;
            }

            .row {
                justify-content: center;

                // Number Circle
                button {
                    @extend %flex-center;
                    height: 2.4em;
                    width: 2.4em;
                    padding: 0;
                    margin: 0.3em 0.4em;
                    font-size: 1.2em;
                    border-radius: 50%;
                    border: 1px solid mix($color-light, transparent, 40);
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        background-color: mix($color-light, transparent, 10);
                    }

                    svg {
                        @extend %absolute-center;
                        height: 70%;
                        width: 70%;

                        path {
                            fill: $color-light;
                        }
                    }
                }
            }

            &-keyboard {
                transition: $transition--fast;
                opacity: 1;
                transform: translateX(0);
            }

            &-action {
                position: absolute;
                top: 1em;
                left: 50%;
                width: 100%;
                transform: translate(calc(-50% + 100px), 0);
                pointer-events: none;
                transition: $transition--fast;
                opacity: 0;
            }
        }

        &-trigger {
            position: relative;
            height: 2.4em;
            width: 2.4em;
            display: block;
            padding: 0;
            margin: auto;
            // margin-top: 4em;
            margin-top: 1em;
            font-size: 1.4em;
            border-radius: 50%;
            background-color: $color-ios-call;

            .far {
                @extend %absolute-center;
                margin: 0;
            }

            .icon {
                @extend %absolute-center;
                height: 86%;
                width: 86%;

                svg {
                    height: 100%;
                    width: 100%;
                    transition: $transition--fast;

                    path {
                        fill: $color-light;
                    }
                }
            }

            &::before {
                @extend %pseudo-tooltip;
            }

            &:hover,
            &:focus {
                background-color: mix($color-ios-call, $color-dark, 80);

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    .phone-voicemail {
        @extend %absolute-center;
        width: 100%;
        transition: $transition--fast;
        opacity: 0;
        padding-left: 10px;
        padding-right: 10px;
        // transform: translate(calc(-50% + 100px), -50%);
        transform: translateX(-50%) translateX(100px) translateY(-50%);
        pointer-events: none;
        text-align: start;
        // background-color: blue;

        // &-description {
        // }

        .voicemail {
            margin-top: 3em;
        }
    }

    .phone.is-calling {
        .phone-call {
            &-field {
                display: none;
            }

            &-name,
            &-status {
                display: block;
            }

            &-wrongnumber.is-active {
                display: none;
            }

            &-body {
                &-keyboard {
                    transform: translateX(-100px);
                    opacity: 0;
                    pointer-events: none;
                }

                &-action {
                    transform: translate(-50%, 0);
                    opacity: 1;
                    pointer-events: inherit;
                }
            }

            &-trigger {
                background-color: $color-primary;

                &::before {
                    opacity: 0;
                }

                &:hover,
                &:focus {
                    background-color: mix($color-primary, $color-dark, 80);
                }

                svg {
                    transform: rotate(135deg);
                }
            }
        }
    }

    .phone.is-voicemail {
        .phone-call {
            // transform: translate(calc(-50% - 100px), -50%);
            transform: translateX(-50%) translateX(-100px) translateY(-50%);
            opacity: 0;
            pointer-events: none;
        }

        .phone-voicemail {
            opacity: 1;
            transform: translate(-50%, -50%);
            pointer-events: inherit;
        }
    }
}

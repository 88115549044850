button,
input[type="submit"],
.btn {
    position: relative;
    padding: 10px 14px;
    text-decoration: none;
    font-size: 1rem;
    // font-weight: 700;
    transition: $transition--fast;
    border: none;
    border-radius: 2px;
    color: $color-light;
    background-color: $color-primary;

    .far:nth-child(1) {
        margin-right: 14px;
    }

    .far:nth-last-child(1) {
        margin-left: 14px;
    }

    &::after {
        @extend %pseudo-loading;
    }

    &:hover,
    &:focus {
        outline: none;
        background-color: mix($color-primary, $color-dark, 80);
    }

    &.small {
        padding-top: 4px;
        padding-bottom: 4px;

        .far {
            font-size: 0.8em;
        }
    }

    &.w-100 {
        width: 100%;
    }

    &.btn-circle {
        // $size: calc((1rem * 1.6) + 10px + 10px);
        $size: 42px;
        height: $size;
        width: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;

        .far {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;
        background-color: $color-button-disabled;
    }

    &.btn-secondary {
        border: 1px solid $color-primary;
        color: $color-primary;
        background-color: transparent;

        &:hover,
        &:focus {
            background-color: mix($color-primary, transparent, 20);
        }

        &:disabled,
        &.is-disabled {
            opacity: 0.4;
            // color: mix($color-primary, transparent, 40);
            // border-color: mix($color-primary, transparent, 40);
            background-color: transparent;
        }
    }

    // TODO: Double check in all element affected
    // - login
    // - subscribe-avatar
    // - subscribe-create
    // - waiting-room
    // - toolbox slider popup (actions)
    // - toolbox phone (play)
    svg {
        height: 1.4em;
        width: 1.4em;
        display: inline-block;
        vertical-align: middle;

        path {
            fill: $color-light;
        }
    }
    // }

    &.is-loading {
        opacity: 0.6;

        span,
        .far {
            opacity: 0;
        }

        &::after {
            display: block;
        }
    }
}
